define("discourse/plugins/discourse-cakeday/discourse/components/user-age-title", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["user-age-title"],
    titleText(title) {
      return _I18n.default.t('js.user.date_of_birth.label') + ': ' + title;
    }
  }, [["method", "titleText", [(0, _decorators.default)("title")]]]));
});