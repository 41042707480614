define("discourse/plugins/discourse-cakeday/discourse/components/emoji-images", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/lib/text", "I18n"], function (_exports, _component, _decorators, _text, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["emoji-images"],
    emojiHTML(list) {
      return list.split("|").map(et => (0, _text.emojiUnescape)(`:${et}:`, {
        skipTitle: true
      }));
    },
    titleText(title) {
      return _I18n.default.t(title);
    }
  }, [["method", "emojiHTML", [(0, _decorators.default)("list")]], ["method", "titleText", [(0, _decorators.default)("title")]]]));
});