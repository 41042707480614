define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday-birthdays-today", ["exports", "@ember/controller", "I18n", "discourse-common/utils/decorators"], function (_exports, _controller, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    title() {
      return _I18n.default.t("birthdays.today.title", {
        date: moment().format(_I18n.default.t("dates.full_no_year_no_time"))
      });
    },
    actions: {
      loadMore() {
        this.get("model").loadMore();
      }
    }
  }, [["method", "title", [_decorators.default]]]));
});