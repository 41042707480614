define("discourse/plugins/discourse-cakeday/admin/connectors/admin-user-below-names/cakeday", ["exports", "discourse/lib/url", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _url, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      if (this.get("user.birthdate") === "1903-04-05") {
        this.set("dateIsMagical", true);
      }
    },
    actions: {
      saveBirthdate(newDate) {
        const oldDate = this.get("user.birthdate");
        this.set("user.birthdate", newDate);
        this.set("dateIsMagical", newDate === "1903-04-05");
        const path = (0, _url.userPath)(`${this.get("user.username").toLowerCase()}.json`);
        return (0, _ajax.ajax)(path, {
          data: {
            date_of_birth: newDate
          },
          type: "PUT"
        }).catch(e => {
          this.set("user.birthdate", oldDate);
          (0, _ajaxError.popupAjaxError)(e);
        }).finally(() => this.toggleProperty("editingBirthdate"));
      },
      unlockBirthdate() {
        const oldDate = this.get("user.birthdate");
        const newDate = '1903-04-05';
        this.set("user.birthdate", newDate);
        this.set("dateIsMagical", true);
        const path = (0, _url.userPath)(`${this.get("user.username").toLowerCase()}.json`);
        return (0, _ajax.ajax)(path, {
          data: {
            date_of_birth: newDate
          },
          type: "PUT"
        }).catch(e => {
          this.set("user.birthdate", oldDate);
          (0, _ajaxError.popupAjaxError)(e);
        });
      },
      setUnlockedBirthdate() {
        document.getElementById('userBirthdate').getElementsByTagName('input').placeholder = 'yyyy-mm-dd';
        const oldDate = this.get("user.birthdate");
        const newDate = '';
        this.set("user.birthdate", newDate);
        this.set("dateIsMagical", false);
        this.toggleProperty("editingBirthdate");
      }
    }
  };
});