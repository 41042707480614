define("discourse/plugins/discourse-cakeday/discourse/controllers/cakeday-birthdays-upcoming", ["exports", "@ember/controller", "I18n", "discourse-common/utils/decorators"], function (_exports, _controller, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    title() {
      const dateFormat = _I18n.default.t("dates.full_no_year_no_time");
      return _I18n.default.t("birthdays.upcoming.title", {
        start_date: moment().add(2, "days").format(dateFormat),
        end_date: moment().add(2, "days").add(1, "week").format(dateFormat)
      });
    },
    actions: {
      loadMore() {
        this.get("model").loadMore();
      }
    }
  }, [["method", "title", [_decorators.default]]]));
});